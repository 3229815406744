import { useEffect, useState } from 'react';
import { FaTag, FaTimes } from 'react-icons/fa';
import { ITagType } from '../../../api/services/tag.service';
import { globalThemeStyles } from '../../../config/globalStyles';
import GenericDialog from '../../shared/dialog/GenericDialog';
import Input from '../../shared/form/Input';
import { Switch } from '@headlessui/react';
import api from '../../../api';
import { toast } from 'react-toastify';
import { BiTag } from 'react-icons/bi';

export function NewTagTypeForm({
  isOpen,
  setIsOpen,
  tenantId,
  reloadTags,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  tenantId: string;
  reloadTags: () => void;
}) {
  const [name, setName] = useState('');
  const [tagsMin, setTagsMin] = useState(0);
  const [tagsMax, setTagsMax] = useState(0);
  const [selectable, setSelectable] = useState(true);
  const [displayName, setDisplayNme] = useState('');

  useEffect(() => {
    setName('');
    setDisplayNme('');
    setTagsMax(0);
    setTagsMin(0);
    setSelectable(false);
  }, []);

  async function addTagType() {
    try {
      await api.tag.addTagType({
        name,
        selectable,
        tenantId,
        displayName,
        tagsMax,
        tagsMin,
      });
      toast.success(`Tag ${name} added successfully!`);
      setIsOpen(false);
      reloadTags();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <GenericDialog
      onUpperRightClick={() => setIsOpen(false)}
      upperRightIcon={
        <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
      }
      header={'Add a new tag type'}
      headerIcon={<BiTag size={20} />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      footer={
        <div className='mt-2 flex items-center justify-end gap-x-3'>
          <button
            className='rounded bg-indigo-600 p-2 px-4 font-semibold text-white hover:bg-indigo-800 disabled:cursor-not-allowed disabled:bg-indigo-300'
            type='submit'
            onClick={() => addTagType()}
            data-testid='reject-submission-submit-btn'
            disabled={name.length === 0}
          >
            Add
          </button>
          <button
            type='button'
            className='rounded p-2 px-4 font-semibold text-slate-600 hover:bg-slate-200'
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
        </div>
      }
    >
      <div className='mt-6 max-w-xl text-base text-gray-600 dark:text-gray-300'>
        <p className=''>Add a new tag type to your label library</p>
      </div>
      <div className='flex flex-col space-y-6'>
        <div className='w-full xl:max-w-xl'>
          <Input
            label='Display name'
            // error={name.length === 0}
            onChange={(e) => setDisplayNme(e.target.value)}
            value={displayName}
            type='text'
            name='displayName'
            id='displayName'
            className={globalThemeStyles.inputbox}
            data-testid='tagTypeDisplayName'
          />
        </div>
        <div className='w-full xl:max-w-xl'>
          <Input
            label='Name'
            // error={name.length === 0}
            onChange={(e) => setName(e.target.value)}
            value={name}
            type='text'
            name='name'
            id='name'
            className={globalThemeStyles.inputbox}
            data-testid='tagTypeName'
          />
        </div>
        <div className='flex w-full space-x-4 xl:max-w-xl'>
          <div className='w-1/2'>
            <Input
              label='Min Tags required'
              error={name.length === 0}
              onChange={(e) => setTagsMin(parseInt(e.target.value))}
              value={tagsMin}
              type='number'
              name='tagsMin'
              id='tagsMin'
              className={globalThemeStyles.inputbox}
              data-testid='tagTypeTagsMin'
            />
          </div>
          <div className='w-1/2'>
            <Input
              label='Max Tags required'
              error={name.length === 0}
              onChange={(e) => setTagsMax(parseInt(e.target.value))}
              value={tagsMax}
              type='number'
              name='tagsMax'
              id='tagsMax'
              className={globalThemeStyles.inputbox}
              data-testid='tagTypeTagsMax'
            />
          </div>
        </div>
        <div className='flex w-full items-center space-x-4 xl:max-w-xl'>
          <div className='flex w-full items-center space-x-4 xl:max-w-xl'>
            <Switch
              checked={selectable}
              onChange={(e) => setSelectable(e)}
              className='group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-indigo-600'
            >
              <span className='size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6' />
            </Switch>
            <div
              className={`flex items-center text-sm font-medium dark:text-slate-200 `}
            >
              Tag selectable in tags tab&nbsp;
            </div>
          </div>
        </div>
      </div>
    </GenericDialog>
  );
}

import { useEffect, useState } from 'react';
import { FaTag, FaTimes } from 'react-icons/fa';
import { ITag } from '../../../api/services/tag.service';
import { globalThemeStyles } from '../../../config/globalStyles';
import GenericDialog from '../../shared/dialog/GenericDialog';
import Input from '../../shared/form/Input';
import NiceSelect from './NiceSelect';
import { BiPurchaseTag } from 'react-icons/bi';

export function NewTagForm({
  isOpen,
  setIsOpen,
  types,
  addTag,
  updateTag,
  selectedTag,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  types: string[];
  addTag: (name: string, type: string) => void;
  updateTag: (id: string, tag: Partial<ITag>) => void;
  selectedTag: ITag | null;
}) {
  const [name, setName] = useState(selectedTag ? selectedTag.name : '');
  const [type, setType] = useState(selectedTag ? selectedTag.type : '');

  useEffect(() => {
    setName(selectedTag ? selectedTag.name : '');
    setType(selectedTag ? selectedTag.type : '');
  }, [selectedTag]);

  return (
    <GenericDialog
      onUpperRightClick={() => setIsOpen(false)}
      upperRightIcon={
        <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
      }
      header={selectedTag ? `Edit tag ${selectedTag.name}` : 'Add a new tag'}
      headerIcon={<BiPurchaseTag size={20} />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      footer={
        <div className='mt-2 flex items-center justify-end gap-x-3'>
          {selectedTag && (
            <button
              className='rounded bg-emerald-600 p-2 px-4 font-semibold text-white hover:bg-emerald-800 disabled:cursor-not-allowed disabled:bg-emerald-300'
              type='submit'
              onClick={() => updateTag(selectedTag.id, { name, type })}
              data-testid='reject-submission-submit-btn'
              disabled={name.length === 0 || type.length === 0}
            >
              Save
            </button>
          )}
          {!selectedTag && (
            <button
              className='rounded bg-indigo-600 p-2 px-4 font-semibold text-white hover:bg-indigo-800 disabled:cursor-not-allowed disabled:bg-indigo-300'
              type='submit'
              onClick={() => addTag(name, type)}
              data-testid='reject-submission-submit-btn'
              disabled={name.length === 0 || type.length === 0}
            >
              Add
            </button>
          )}
          <button
            type='button'
            className='rounded p-2 px-4 font-semibold text-slate-600 hover:bg-slate-200'
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
        </div>
      }
    >
      <div className='mt-6 max-w-xl text-base text-gray-600 dark:text-gray-300'>
        <p className=''>Add a new tag to your label library</p>
      </div>
      <div className='flex flex-col space-y-6'>
        <div className='w-full xl:max-w-xl'>
          <Input
            label='Name'
            // error={name.length === 0}
            onChange={(e) => setName(e.target.value)}
            value={name}
            type='text'
            name='name'
            id='name'
            className={globalThemeStyles.inputbox}
            data-testid='add-tag'
          />
        </div>
        <div className='w-full xl:max-w-xl'>
          <label
            htmlFor={'type'}
            className={`flex items-center text-sm font-medium dark:text-slate-200 `}
          >
            Type&nbsp;
            {/* {hint && <InfoTooltip hint={hint} />} */}
          </label>
          <NiceSelect
            canBeModified={true}
            catalogue={type}
            updateCatalogue={setType}
            options={types}
          />
        </div>
      </div>
    </GenericDialog>
  );
}

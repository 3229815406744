import { IResponse } from '..';
import client from '../client';

// Existing tag interfaces
export interface ITag {
  id: string;
  teamId: string;
  name: string;
  type: string;
  createdBy?: string;
}

export interface INewTag {
  teamId: string;
  name: string;
  type: string;
}

export interface ITagMapping {
  tagId: string;
  mappedObjectId: string;
  mappedObjectType: string;

  createdAt?: string;
  createdBy?: string;
}

export interface ITagType {
  id: string;
  tenantId: string;
  name: string;
  description: string;
  displayName: string;
  tagsMax: number;
  tagsMin: number;
  selectable: boolean;
  createdAt: string; // Assuming ISO date string format
  updatedAt: string; // Assuming ISO date string format
}

export interface INewTagType {
  tenantId: string;
  name: string;
  selectable: boolean;
  displayName: string;
  tagsMax: number;
  tagsMin: number;
}

async function getTags() {
  return client.get<IResponse<ITag[]>>(`/tag`);
}

async function addTag(tag: INewTag) {
  return client.post<IResponse<ITag>>(`/tag`, tag);
}

async function deleteTag(id: string) {
  return client.delete<IResponse<ITag>>(`/tag/${id}`);
}

async function getTagsByObject(objectId: string) {
  return client.get<IResponse<ITag[]>>(`/tag/mappedObject:${objectId}`);
}

async function addTagToObject(
  tagId: string,
  objectType: string,
  objectId: string
) {
  return client.post<IResponse<ITagMapping>>(`/tag/${tagId}/mappedObject`, {
    mappedObjectId: objectId,
    mappedObjectType: objectType,
    tagId,
  });
}

async function removeTagFromObject(tagId: string, objectId: string) {
  return client.delete<IResponse<ITagMapping>>(
    `/tag/${tagId}/mappedObject/${objectId}`
  );
}

async function updateTagPartially(tagId: string, tag: Partial<ITag>) {
  return client.patch<IResponse<ITag>>(`/tag/${tagId}`, tag);
}

async function getTagTypes() {
  return client.get<IResponse<ITagType[]>>(`/tagType`);
}

async function getTagTypeById(id: string) {
  return client.get<IResponse<ITagType>>(`/tagType/${id}`);
}

async function addTagType(tagType: INewTagType) {
  return client.post<IResponse<ITagType>>(`/tagType`, tagType);
}

async function updateTagTypePartially(id: string, tagType: Partial<ITagType>) {
  return client.patch<IResponse<ITagType>>(`/tagType/${id}`, tagType);
}

async function deleteTagType(id: string) {
  return client.delete<IResponse<null>>(`/tagType/${id}`);
}

const api = {
  // tag methods
  getTags,
  getTagsByObject,
  addTag,
  deleteTag,
  addTagToObject,
  removeTagFromObject,
  updateTagPartially,

  // tagType methods
  getTagTypes,
  getTagTypeById,
  addTagType,
  updateTagTypePartially,
  deleteTagType,
};

export default api;

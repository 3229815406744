import { useEffect, useState } from 'react';
import { ITag, ITagType } from '../api/services/tag.service';
import api from '../api';

export const useTags = () => {
  const [allTags, setAllTags] = useState<ITag[]>([]);
  const [activeTags, setActiveTags] = useState<ITag[]>([]);

  const [tagTypes, setTagTypes] = useState<ITagType[]>([]);
  const [selectableTagTypes, setSelectableTagTypes] = useState<ITagType[]>([]);
  const [activeTagType, setActiveTagType] = useState<ITagType | null>(null);

  useEffect(() => {
    fetchTags();
    fetchTagTypes();
  }, []);

  async function fetchTags() {
    try {
      const { data } = await api.tag.getTags();
      if (data.result) {
        setAllTags(data.result);
      } else {
        setAllTags([]);
      }
    } catch (e) {
      console.log(e);
      setAllTags([]);
    }
  }

  async function fetchTagTypes() {
    try {
      const { data } = await api.tag.getTagTypes();
      if (data.result) {
        const sortedTagTypes = data.result.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        const selectableTypes = sortedTagTypes.filter(
          (tagType) => tagType.selectable
        );
        setTagTypes(sortedTagTypes);
        setSelectableTagTypes(selectableTypes);
        if (activeTagType === null && data.result.length > 0) {
          setActiveTagType(selectableTypes[0]);
        }
      } else {
        setTagTypes([]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return {
    activeTagType,
    setActiveTagType,
    activeTags,
    setActiveTags,
    allTags,
    setAllTags,
    tagTypes,
    selectableTagTypes,
    fetchTags,
    fetchTagTypes,
  };
};

import { DateTime } from 'luxon';
import { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import api, { IValidationErrorResponse } from '../../api';
import { BiGroup, BiMailSend, BiUser, BiUserPlus } from 'react-icons/bi';
import {
  ITeam,
  ITeamInvite,
  ITeamMembership,
} from '../../api/services/team.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import TextEditDialog from '../shared/dialog/TextEditDialog';

interface Props {
  className?: string;
  members: ITeamMembership[];
  team: ITeam;
  invites: ITeamInvite[];
  fetchInvites: () => void;
}

export default function TeamMemberSection({
  className,
  members,
  team,
  invites,
  fetchInvites,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isMemberAddDialogOpen, setIsMemberAddDialogOpen] = useState(false);

  function getToastErrorMessage(response: IValidationErrorResponse) {
    const violations = response.result;
    return (
      <div className='text-sm text-gray-700'>
        The following issues occured:
        <div className='space-y-3 pt-2'>
          {violations.map((t) => (
            <div key={t.objectId} className='space-y-2'>
              {/* {JSON.stringify(t)} */}
              {t.fieldName && (
                <div>
                  <b>{t.fieldName}</b> {t.message}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  async function addMember({ email }: { email: string }) {
    try {
      await api.team.inviteMember(team?.id || '', {
        targetUserEmail: email,
        targetRole: 'MEMBER',
      });
      toast.success(`Invitation sent to ${email}!`);
      fetchInvites();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          if (e.response?.data.errorCode === 'KTR-BASE-0005') {
            toast.error(getToastErrorMessage(e.response.data));
          } else {
            toast.error(e.response?.data.errorMessage);
          }
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  function MembershipRow({ membership }: { membership: ITeamMembership }) {
    const targetUser = membership.user;
    const platformRole = membership.role === 'GUEST' ? 'ARTIST' : 'STAFF';
    const isInvite = targetUser.firstname.indexOf('@') > -1;
    return (
      <div
        key={membership.userId}
        className='relative flex h-24 overflow-hidden break-all rounded-lg bg-white px-4 py-2 shadow hover:cursor-pointer hover:bg-slate-50 dark:bg-slate-900 hover:dark:bg-slate-800 md:h-20 lg:h-24 xl:h-16'
      >
        <div className='flex w-full items-center space-x-4'>
          {isInvite ? (
            <div className='flex rounded-md bg-slate-500 p-2'>
              <BiMailSend className='text-white' />
            </div>
          ) : (
            <div className='flex rounded-md bg-indigo-500 p-2'>
              <BiUser className='text-white' />
            </div>
          )}

          <div className='flex-1 '>
            <div className='flex items-center justify-between'>
              {targetUser && (
                <h3 className='text-sm font-medium'>
                  {targetUser['firstname']}
                  {targetUser['lastname'] ? ' ' + targetUser['lastname'] : ''}
                  {isInvite && <span className='text-xs'> (Invited)</span>}
                  {!isInvite && (
                    <span className='text-xs'> ({targetUser['email']})</span>
                  )}
                </h3>
              )}
              <div
                className={`flex h-5 items-center justify-center rounded px-2.5 py-0.5 text-sm font-semibold ${
                  isInvite
                    ? 'bg-slate-500 text-white'
                    : platformRole === 'ARTIST'
                    ? 'bg-teal-500 text-white'
                    : 'bg-amber-500 text-white'
                }`}
              >
                <div>{platformRole.toUpperCase()}</div>
              </div>
            </div>
            <div className='break-all text-sm text-gray-500'>
              {DateTime.fromJSDate(
                new Date(membership.createdAt ?? '')
              ).toRelative()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <TextEditDialog
        isOpen={isMemberAddDialogOpen}
        title={'Invite a colleague'}
        placeholder='Enter email address'
        onCancel={() => {
          setIsMemberAddDialogOpen(false);
        }}
        onSubmit={(newValue: string) => {
          addMember({ email: newValue });
          setIsMemberAddDialogOpen(false);
        }}
      />
      <div className='flex flex-col space-y-5'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-2'>
            <BiGroup className='text-2xl text-indigo-500' />
            <div className='text-lg font-semibold'>Label Members</div>
          </div>
          {/* <div
            className='cursor-pointer rounded-lg bg-indigo-500 p-1 px-2'
            onClick={() => setIsMemberAddDialogOpen(true)}
          >
            <BiUserPlus className='text-xl text-white' />
          </div> */}
          <div
            className='flex cursor-pointer items-center space-x-2 rounded-lg bg-indigo-500 p-1 px-2'
            onClick={() => setIsMemberAddDialogOpen(true)}
          >
            <BiUserPlus className='text-xl text-white' />
            <div>Invite Member</div>
          </div>
        </div>

        <dl className='flex h-120 w-full flex-col overflow-scroll'>
          {loading && (
            <div className='flex items-center justify-center p-20 text-slate-500'>
              <FaSpinner size={30} className='animate-spin' />
            </div>
          )}
          {!loading && members.length > 0 && (
            <div className='flex flex-col space-y-4'>
              {invites
                .filter((invite) => invite.status !== 'APPROVED')
                .map((invite, i) => (
                  <MembershipRow
                    key={i}
                    membership={{
                      role: invite.teamRole,
                      userId: invite.targetUserId,
                      createdAt: invite.createdAt,
                      teamId: invite.teamId,
                      user: {
                        firstname: invite.targetUserEmail,
                        lastname: '',
                        email: invite.targetUserEmail,
                        avatar: '',
                        id: invite.targetUserId,
                      },
                    }}
                  />
                ))}
              {members.map((membership, i) => (
                <MembershipRow key={i} membership={membership} />
              ))}
            </div>
          )}
        </dl>
      </div>
    </>
  );
}

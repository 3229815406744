import { BiListPlus, BiPurchaseTag, BiTag } from 'react-icons/bi';
import { ITeam } from '../../api/services/team.service';
import StatusBadge from '../shared/StatusBadge';
import { useEffect, useState } from 'react';
import { ITag } from '../../api/services/tag.service';
import api from '../../api';
import { toast } from 'react-toastify';
import { useTags } from '../../hooks/useTags';
import { NewTagForm } from './tagSection/NewTagForm';
import {
  ListTagsForm,
  mapTagTypeToColorClass,
} from './tagSection/ListTagsForm';
import { setTag } from '@sentry/react';
import { NewTagTypeForm } from './tagSection/NewTagTypeForm';

export function TagSection({ team }: { team: ITeam }) {
  const [isTagAddDialogOpen, setIsTagAddDialogOpen] = useState(false);
  const [isTagListDialogOpen, setIsTagListDialogOpen] = useState(false);
  const [isTagTypeAddDialogOpen, setIsTagTypeAddDialogOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);

  const {
    tagTypes,
    fetchTags,
    activeTagType,
    setActiveTagType,
    allTags,
    fetchTagTypes,
  } = useTags();

  useEffect(() => {
    console.log(allTags.filter((tag) => tag.type === activeTagType?.name));
  }, [allTags, activeTagType]);

  async function addTag(name: string, type: string) {
    try {
      await api.tag.addTag({
        name,
        teamId: team.id,
        type: type.toUpperCase(),
      });
      toast.success(`Tag ${name} added successfully!`);
      setIsTagAddDialogOpen(false);
      fetchTags();
    } catch (e) {
      console.error(e);
    }
  }

  async function updateTag(id: string, tag: Partial<ITag>) {
    try {
      await api.tag.updateTagPartially(id, tag);
      toast.success(`Tag ${tag.name} updated successfully!`);
      setIsTagAddDialogOpen(false);
      fetchTags();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div>
      <NewTagForm
        selectedTag={selectedTag}
        isOpen={isTagAddDialogOpen}
        setIsOpen={setIsTagAddDialogOpen}
        types={tagTypes.map((tagType) => tagType.name)}
        addTag={addTag}
        updateTag={updateTag}
      />
      <NewTagTypeForm
        isOpen={isTagTypeAddDialogOpen}
        setIsOpen={setIsTagTypeAddDialogOpen}
        reloadTags={() => {
          fetchTags();
          fetchTagTypes();
        }}
        tenantId={team.id}
      />

      <ListTagsForm
        isOpen={isTagListDialogOpen}
        setIsOpen={setIsTagListDialogOpen}
        tagType={activeTagType}
        tags={allTags.filter((tag) => tag.type === activeTagType?.name)}
        reloadTags={() => {
          fetchTags();
          fetchTagTypes();
          setActiveTagType(activeTagType);
        }}
        setIsEditOpen={(tagId) => {
          const tag = allTags.find((tag) => tag.id === tagId);
          if (tag) {
            setIsTagAddDialogOpen(true);
            setSelectedTag(tag);
          }
        }}
      />

      <div className='flex flex-col space-y-5'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-2'>
            <BiPurchaseTag className='text-2xl text-indigo-500' />
            <div className='text-lg font-semibold'>Tag Management</div>
          </div>
          <div className='flex space-x-4'>
            <div
              className='flex cursor-pointer items-center space-x-2 rounded-lg bg-indigo-500 p-1 px-2'
              onClick={() => {
                setSelectedTag(null);
                setIsTagAddDialogOpen(true);
              }}
            >
              <BiPurchaseTag className='text-xl text-white' />
              <div>New Tag</div>
            </div>
            <div
              className='flex cursor-pointer items-center space-x-2 rounded-lg bg-indigo-500 p-1 px-2'
              onClick={() => {
                setActiveTagType(null);
                setIsTagTypeAddDialogOpen(true);
              }}
            >
              <BiTag className='text-xl text-white' />
              <div>New Tag Type</div>
            </div>
          </div>
        </div>

        <div className='flex w-full flex-wrap items-end justify-start space-x-2 space-y-2 overflow-scroll'>
          {tagTypes.map((tagType) => (
            <div
              key={tagType.name}
              className='flex cursor-pointer'
              onClick={() => {
                setActiveTagType(tagType);
                setIsTagListDialogOpen(true);
              }}
            >
              <StatusBadge
                status={tagType.name}
                className={mapTagTypeToColorClass(tagType.name)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
